<template>
  <div class="reviews--wrapper">
    <div class="ts-badge__desktop">
      <div class="ts-badge__desktop--content">
        <div
          class="ts-badge__desktop--lower"
          :aria-label="'Trusted Shops Kundengesamtbewertung ' + overallMarkText"
        >
          <div class="ts-badge__desktop__stars">
            <Stars :value="overallMark" width="100px" />
          </div>

          <p class="slim" :aria-hidden="true">{{ overallMarkText }}</p>

          <p class="slim" :aria-hidden="true">{{ overallMark }} / 5,00</p>
        </div>

        <div class="ts-badge__desktop-footer">
          <div class="ts-badge__desktop-footer__triangle"></div>

          <div
            class="ts-badge__desktop-footer__bottom-slant"
            role="img"
            :aria-hidden="true"
          >
            <svg
              width="100%"
              height="20"
              viewBox="0 0 10 5"
              preserveAspectRatio="none"
            >
              <polygon
                :fill="isHighContrastModeActive ? '#313131' : '#ffffff'"
                stroke-width="0"
                points="0,3 0,0 180,0 16,0"
              />
            </svg>
          </div>
        </div>

        <div class="ts-badge__desktop-footer-content" :aria-hidden="true">
          <span>
            Kunden-
            <br />bewertungen
          </span>

          <Icon name="e-trustedshops" color="black" up="5" height="24" />
        </div>
      </div>
    </div>

    <div class="customer-reviews">
      <h3 class="subsubtitle customer-reviews--heading">
        Das sagen unsere Kunden über
        <span v-if="totalReviewCount">
          uns - {{ totalReviewCount }} Bewertungen insgesamt:</span
        >
        <span v-else>uns:</span>
      </h3>

      <ul class="customer-reviews__list">
        <li
          v-for="(review, index) in visibleReviews"
          :key="`review-${index}`"
          class="customer-review"
        >
          <div class="customer-review--date">
            <Stars
              class="customer-review--rating"
              :value="review.mark"
              width="80px"
            />

            <span>{{ review.date }}</span>
          </div>

          <p class="slim customer-review--comment">{{ review.comment }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { getErrorMessage } from 'src/lib/error_message_helper.js'
import { mapState } from 'vuex'
import { numberFormat, parseTrustedShopsMark } from 'src/lib/helpers'
import Icon from 'src/components/common/icon/icon.vue'
import Stars from 'src/components/order/common/trustedshops/components/stars.vue'

export default {
  name: 'PortalReviewsComponent',
  components: {
    Stars,
    Icon
  },
  inject: ['$http'],
  data() {
    return {
      reviews: [],
      overallMark: undefined,
      overallMarkText: undefined,
      totalReviewCount: undefined
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme
    }),
    reviewCount() {
      return this.$breakpoint.fromDesktop()
        ? 3
        : this.$breakpoint.fromTablet()
        ? 2
        : 1
    },
    visibleReviews() {
      return this.reviews.slice(0, this.reviewCount)
    },
    isHighContrastModeActive() {
      return this.theme === DARK
    }
  },
  created() {
    this.requestTsRating()
    this.requestTsReviews()
  },
  methods: {
    async requestTsRating() {
      try {
        const response = await this.$http.get(
          '/rest-api/v3/trusted-shops/quality'
        )

        try {
          this.overallMark = numberFormat(response.data['365days'].rating)
          if (response.data.overall && response.data.overall.count) {
            this.totalReviewCount = response.data.overall.count
          }

          this.overallMarkText = parseTrustedShopsMark(this.overallMark)
        } catch (e) {
          this.$bus.emit('trustedshops-load-error', e)
          console.error('Error parsing trustedshops data', e)
        }
      } catch (e) {
        this.$bus.emit('trustedshops-load-error', e)
        console.error(
          'Error fetching trustedshops data',
          getErrorMessage(e.response)
        )
      }
    },

    async requestTsReviews() {
      try {
        const response = await this.$http.get(
          '/rest-api/v3/trusted-shops/reviews'
        )

        try {
          const allReviews = response.data.items
          const maxReviewCount = 3

          for (let i = 0; i < maxReviewCount; i++) {
            let parsedReview = {
              comment: allReviews[i].comment,
              date: this.parseDate(allReviews[i].updatedAt),
              mark: allReviews[i].rating
            }

            this.reviews.push(parsedReview)
          }
        } catch (e) {
          this.$bus.emit('trustedshops-load-error', e)
          console.error('Error parsing trustedshops data', e.response)
        }
      } catch (e) {
        this.$bus.emit('trustedshops-load-error', e)
        console.error(
          'Error fetching trustedshops data',
          getErrorMessage(e.response)
        )
      }
    },

    parseDate(dateString) {
      let date = new Date(dateString)

      let day =
        date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate()
      let month = date.getUTCMonth() + 1
      let year = date.getUTCFullYear()

      return `${day}.${month}.${year}`
    }
  }
}
</script>

<style lang="scss" scoped>
.reviews--wrapper {
  display: flex;
  gap: 15px;
  margin: 30px 8px 5px;
  color: var(--root-color);

  @include breakpoint($up-to-tablet) {
    flex-direction: column;
    align-items: center;
    margin: 10px 8px;
  }
}

.customer-reviews__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.customer-reviews {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  > ul {
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
  }

  @include breakpoint($up-to-tablet) {
    margin-left: 0;
    margin-top: 20px;
    align-items: center;
  }
}

.customer-reviews--heading {
  line-height: 1.2;
  margin-bottom: 10px;
}

.customer-reviews__list {
  display: flex;
  padding: 0;
  gap: 10px;
}

.customer-review {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  background-color: var(--background-primary);
  border-radius: 6px;
  padding: 10px;
}

.customer-review--date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--paragraph-color);
  font-size: 0.8rem;
  white-space: nowrap;

  > span {
    min-width: 4rem;
  }

  .customer-review--rating {
    display: flex;
    margin-right: 10px;
    min-width: 80px;
    height: 15px;
  }
}

.customer-review--comment {
  text-align: left;
  font-weight: bold;
  font-size: 0.9rem;
  max-height: calc-rem(110px);
  padding: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.ts-badge__desktop {
  padding-right: 35px;
  margin-top: 0.5rem;

  @include breakpoint($up-to-desktop) {
    padding-right: 0;
  }
}

.ts-badge__desktop--content {
  color: $c_night-blue;
  background-color: var(--background-primary);
  width: 180px;
  outline: 5px solid #ffdc0f;
  border-radius: 1px;
}

.ts-badge__desktop-footer-content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: calc-rem(60px);
  background-color: #ffdc0f;
  align-items: flex-end;

  > span {
    font-size: 0.9rem;
    line-height: 1.1;
  }
}

.ts-badge__desktop--lower {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 5px;

  p {
    color: var(--paragraph-color);
  }
}

.ts-badge__desktop__stars {
  width: 170px;
  height: 40px;
  padding-top: 5px;
}

.ts-badge__desktop-footer {
  position: relative;
}

.ts-badge__desktop-footer__bottom-slant {
  position: relative;
  top: 9px;
  height: calc-rem(13px);
}

.ts-badge__desktop-footer__triangle {
  position: absolute;
  top: calc-rem(13px);
  right: 40px;
  height: 0;
  border-width: 4px 20px 17px 0;
  width: 0;
  border-style: solid;
  border-color: transparent var(--background-primary) transparent transparent;
}
</style>
